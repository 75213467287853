/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
import './src/styles/global.css'
import 'keen-slider/keen-slider.min.css'
import 'vanilla-cookieconsent/dist/cookieconsent.css'

// Scroll from different pages
const scrollTo = (id) => () => {
    const el = document.querySelector(id)
    if (el) return window.scrollTo(0, el.offsetTop - 20)
    return false
}

export const onRouteUpdate = ({ location: { hash } }) => {
    if (hash) {
        window.setTimeout(scrollTo(hash), 100)
    }
}